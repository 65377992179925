import { useEffect } from "react";
import { connect } from "react-redux";
import { loginUser } from "../actions";

function LoginHelper(props) {
  useEffect(() => {
    // Dispatch loginUser action once the component is mounted
    props.dispatch(
      loginUser(
        props.customerByCode.firstName,
        props.customerByCode.lastName,
        props.customerByCode.id,
        props.customerByCode.email,
        props.customerByCode.code,
        props.customerByCode.duiten
      )
    );
  }, [props.dispatch, props.customerByCode]);

  return null;
}

export default connect()(LoginHelper);
