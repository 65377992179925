import { gql } from "apollo-boost";

export const COMPANIES = gql`
  {
    companies {
      id
      name
      code
      duiten
      endDate
      emailId
      employees {
        id
        firstName
        lastName
      
      }
    }
  }
`;

export const ARCHIVEDCOMPANIES = gql`
  {
    archivedCompanies {
      id
      name
      code
      duiten
      endDate
      emailId
      employees {
        id
        firstName
        lastName
      
      }
    }
  }
`;

export const COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      name
      code
      duiten
      endDate
      emailId
      archived
      employees {
        code
        id
        firstName
        insertion
        lastName
        email
        gender
        duiten
        order {
          id
          processed
          createdAt
          products {
            id
            price
            hasProduct {
              name
              price
              code
              taxpercent
            }
          }
        }
      }
    }
  }
`;

export const COMPANY_CODES = gql`
  query company($id: ID!) {
    company(id: $id) {
      employees {
        code
      }
    }
  }
`;

export const ARCHIVE_COMPANY = gql`
  mutation archiveCompany($id: ID!) {
    archiveCompany(id: $id) {
      id
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany(
    $name: String!
    $code: String!
    $duiten: Int!
    $endDate: String!
    $emailId: String!
    $reminderEmailId: String!
  ) {
    createCompany(
      name: $name
      code: $code
      duiten: $duiten
      endDate: $endDate
      emailId: $emailId
      reminderEmailId: $reminderEmailId
    ) {
      id
      name
      code
      duiten
      endDate
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $id: ID!
    $name: String!
    $code: String!
    $duiten: Int!
    $endDate: String!
    $emailId: String!
    $reminderEmailId: String!
  ) {
    updateCompany(
      id: $id
      name: $name
      code: $code
      duiten: $duiten
      endDate: $endDate
      emailId: $emailId
      reminderEmailId: $reminderEmailId
    ) {
      id
      name
      code
      duiten
      endDate
    }
  }
`;

export const ORDERS_BY_COMPANY = gql`
  query ordersByCompany($company: ID, $processed: Boolean) {
    ordersByCompany(company: $company, processed: $processed) {
      id
      processed
      createdAt
      products {
        price
        hasProduct {
          name
          price
          code
        }
      }
      customer {
        code
        id
        firstName
        lastName
        email
        duiten
      }
    }
  }
`;
