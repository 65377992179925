import React from "react";
import Routes from "../routes/Routes";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import { connect } from "react-redux";
import { Translator } from "react-auto-translate";

const cacheProvider = {
  get: (language, key) =>
    ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
      language
    ],
  set: (language, key, value) => {
    const existing = JSON.parse(localStorage.getItem("translations")) || {
      [key]: {}
    };
    existing[key] = { ...existing[key], [language]: value };
    localStorage.setItem("translations", JSON.stringify(existing));
  }
};

function ApolloContainer(props) {
  const client = new ApolloClient({
    //cache: new InMemoryCache(),
    request: operation => {
      operation.setContext({
        headers: {
          Authorization: props.adminAuth.token
        }
      });
    },

    // uri: "http://localhost:4000/"  // local
    // uri: "https://test-api-zeghetmetduiten-shop.mytestpartner.nl/" //demo
    uri: "https://api.zeghetmetduiten.nl/" // live

  });

  return (
    <Translator
      cacheProvider={cacheProvider}
      from="nl"
      to={props.language}
      googleApiKey="AIzaSyBb2n14hBoreIGpKUqzyKKXHuDZE4Vd_30"
    >
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </Translator>
  );
}
const mapStateToProps = state => {
  const { adminAuth, language } = state;
  return { adminAuth, language };
};
export default connect(mapStateToProps)(ApolloContainer);
